.product {
  &__title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 8px;
  }
  &__desc {
    color: lightgray;
    margin-bottom: 32px;
  }
  &__search {
    text-align: center;
  }
  &__list {
    display: grid;
    gap: 32px;
    grid-template-columns: repeat(4, 1fr);

    @media (max-width: 1400px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media (max-width: 980px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 540px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  &__card {
    position: relative;
  }
  &__image {
    width: 100%;
    height: 400px;
    object-fit: cover;
    margin-bottom: 8px;
  }
  &__name {
    font-size: 18px;
    margin-bottom: 8px;
    color: #fff;
    text-decoration: none;
  }
  &__price {
    font-weight: bold;
    color: #fff;
    text-decoration: none;
    margin-bottom: 8px;
  }
  &__colorway {
    color: lightgray;
    margin-bottom: 8px;
    font-size: 0.8em;
  }
  &__first--info {
    display: flex;
  }
  &__button {
    border: 1px solid #fff;
    margin-top: 16px;
    padding: 10px 16px;
    background: transparent;
    color: #fff;
    font-size: 18px;
    cursor: pointer;
  }
  &__favorite {
    position: absolute;
    right: 0;
    bottom: 25px;
  }
  &__card {
    position: relative;
  }
  &__overlay {
    position: absolute;
    top: 0;
    height: 400px;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    z-index: 1;
  }
  &__link {
    width: 80%;
    background-color: #fff;
    margin-bottom: 24px;
    padding: 12px 12px;
    text-align: center;
    font-weight: 700;
    color: #000;
    text-transform: uppercase;
    text-decoration: none;
    &--acb {
      background-color: #00bebf;
      color: #fff;
      &:hover {
        background-color: darken(#00bebf, 10%);
      }
    }
    &--superbuy {
      background-color: #0083ef;
      color: #fff;
      &:hover {
        background-color: darken(#0083ef, 10%);
      }
    }
    &--mulebuy {
      background-color: #ff3d01;
      color: #fff;
      &:hover {
        background-color: darken(#ff3d01, 10%);
      }
    }
    &--hoobuy {
      background-color: #f7b500;
      color: #fff;
      &:hover {
        background-color: darken(#f7b500, 10%);
      }
    }
    &--cnfans {
      background-color: #e83820;
      color: #fff;
      &:hover {
        background-color: darken(#e83820, 10%);
      }
    }
  }
}

.section-outfit {
  margin-top: 80px;
}

.filter {
  display: flex;
  justify-content: flex-start;
  gap: 32px;
  margin-bottom: 32px;
  &__select {
    cursor: pointer;
    border: 2px solid #203645;
    background: TRANSPARENT;
    color: #fff;
    border-radius: 0px;
    padding: 0.75em;
    font-size: 18px;
  }
  &__search {
    border: 2px solid #203645;
    background: TRANSPARENT;
    color: #fff;
    border-radius: 0px;
    padding: 0.75em;
    font-size: 18px;
  }
  &__reset {
    border: none;
    background: #203645;
    color: #fff;
    border-radius: 0px;
    padding: 0.75em;
    font-size: 18px;
    cursor: pointer;
    transition: all 0.4s;
    &:hover {
      background-color: #4d6474;
    }
  }
  @media (max-width: 980px) {
    flex-direction: column;
  }
}
