@import "Reset";
@import "Home";
@import "Product";
@import "Outfit";

html {
  background-image: linear-gradient(
    to left bottom,
    #15151c,
    #171820,
    #191b25,
    #1b1f29,
    #1d222e,
    #1e2431,
    #1e2734,
    #1f2937,
    #1e2b39,
    #1e2c3b,
    #1d2e3c,
    #1c303e
  );
}
.App {
  color: #fff;
  font-family:
    system-ui,
    -apple-system,
    "Segoe UI",
    "Roboto",
    "Ubuntu",
    "Cantarell",
    "Noto Sans",
    sans-serif,
    "Apple Color Emoji",
    "Segoe UI Emoji",
    "Segoe UI Symbol",
    "Noto Color Emoji";
  min-height: 100dvh;
}
.container {
  max-width: 1280px;
  padding-left: 4rem;
  padding-right: 4rem;
  margin: 0 auto;
}
.no-scroll {
  overflow: hidden;
  height: 100%;
}

@media (max-width: 540px) {
  .container {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}
@media (max-width: 400px) {
  .container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
