.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 4rem;
  background-color: transparent;
  max-width: 1280px;
  margin: 0 auto 80px;
  position: relative;

  &__logo {
    width: 100px;
  }

  &__nav {
    display: flex;
    gap: 32px;
    align-items: center;
    background-color: rgba(250, 250, 250, 0.2);
    padding: 8px 32px;
    border-radius: 2em;
    &__favorite {
      position: absolute;
      right: -1px;
      top: 1px;
    }

    a {
      color: #a5a5a5;
      text-decoration: none;
      padding: 8px 12px;
      border-radius: 2em;
      position: relative;
      transition:
        background-color 0.4s ease,
        color 0.3s ease;

      &:hover {
        background-color: rgba(250, 250, 250, 0.2);
      }

      &.activeLink {
        background-color: rgba(250, 250, 250, 0.2);
        color: #fff;
      }
    }
  }
}

.banner {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__title {
    font-size: 2rem;
    text-transform: uppercase;
    margin-bottom: 16px;
    font-weight: bold;
  }

  &__more {
    display: inline-block;
    position: relative;
    padding-bottom: 4px;
    text-decoration: none;
    color: lightgray;

    &:after {
      content: "";
      position: absolute;
      width: 100%;
      transform: scaleX(0);
      height: 2px;
      bottom: 0;
      left: 0;
      background-color: lightgray;
      transform-origin: bottom right;
      transition: transform 0.25s ease-out;
    }

    &:hover:after {
      transform: scaleX(1);
      transform-origin: bottom left;
    }
  }
}

.brand {
  padding-top: 100px;

  &__title {
    font-size: 2rem;
    text-transform: uppercase;
    margin-bottom: 16px;
    font-weight: bold;
  }

  &__logo {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    align-items: center;
    justify-items: center;
    padding-top: 60px;

    img {
      width: 200px;
      height: 100px;
    }
  }
}

.footer {
  padding-top: 150px;
  padding-bottom: 50px;

  &__name {
    text-align: center;
  }
}

@media (max-width: 980px) {
  .brand__logo {
    grid-template-columns: repeat(2, 1fr);
    gap: 32px;
  }
}

@media (max-width: 540px) {
  .brand__logo {
    grid-template-columns: repeat(1, 1fr);
  }

  .header {
    padding: 16px 3rem;
  }
}

@media (max-width: 400px) {
  .header {
    padding: 16px 2rem;
  }
}

@media (max-width: 800px) {
  .nav-burger {
    display: flex;
    cursor: pointer;
    flex-direction: column;
    gap: 5px;
    z-index: 1001;

    span {
      display: block;
      height: 3px;
      width: 25px;
      background-color: #fff;
      transition: transform 0.5s ease;
    }

    &.open span:nth-child(1) {
      transform: translateY(8px) rotate(45deg);
    }

    &.open span:nth-child(2) {
      opacity: 0;
    }

    &.open span:nth-child(3) {
      transform: translateY(-8px) rotate(-45deg);
    }
  }

  .header__nav {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    width: 50%;
    height: 100vh;
    background-color: #000;
    border-radius: 0;
    flex-direction: column;
    transform: translateX(100%);
    transition: transform 0.3s ease-out;
    z-index: 1000;

    &.open {
      display: flex;
      transform: translateX(0);
      justify-content: center;
    }
  }
}
