.outfit {
  display: grid;
  gap: 32px;
  grid-template-columns: repeat(3, 1fr);
}

.outfit__image {
  width: 100%;
  height: 700px;
  object-fit: cover;
}

.outfit__grid {
  display: flex;
  justify-content: space-around;
  &__image {
    width: 400px;
    height: 700px;
    object-fit: cover;
  }
}

.outfit__product {
  display: flex;
  flex-direction: column;
  &__card {
    display: flex;
    gap: 16px;
    align-items: center;
    margin-bottom: 16px;
  }
  &__image {
    width: 250px;
    height: 250px;
    object-fit: cover;
  }
}
@media (max-width: 980px) {
  .outfit {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 540px) {
  .outfit {
    grid-template-columns: repeat(1, 1fr);
  }
}
@media (max-width: 800px) {
  .outfit__grid {
    flex-direction: column;
    gap: 32px;
    &__image {
      width: 100%;
    }
  }
  .outfit__product {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 32px;
    justify-content: center;
    &__card {
      flex-direction: column;
      align-items: start;
    }
  }
}
